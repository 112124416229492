export const bio= "\tHello, my name is Brian Baldner, and welcome to my website. This website started as a place for me to show my progress as a programmer over time, but it soon became my favorite project I have made and the first place I point people who want to know more about what I do. I started programming in 7th grade when I wanted to curate a website with all of my favorite browser games. This website made me locally famous in my middle school, and once I saw the effect this little project of mine had, I was all in. My first real projects were games that I could host on the site, and I kept learning the intricacies of software development through small personal projects. A few of my favorite projects to show off are below, but I have more small projects that aren’t as easy to present. In September 2024, I started at University of Oregon double majoring in Computer Science and Data Science and am also in the Clark Honors College. Thank you for visiting my site!";

export const credits= "I got my inspiration for this from Henry Heffernan. Check his version out at <a target=\"_blank\" href=\"https://henryheffernan.com/\">henryheffernan.com</a><br>The design of this page was made to resemble <a target=\"_blank\" href=\"https://wikipedia.org/\">wikipedia.org</a><br>The desktop uses images from Microsoft Windows";

export const ultimateGames = "Ultimate Games was my first project as a programmer. It was a website that had a ton of games on flash games that my friends would play. I made it with pure HTML and CSS."

export const volleystat = "One thing I have done longer than software development is play volleyball, and I noticed how much time my teams would waste with paper stats. My solution to this problem was VolleyStat, made with React Native and Expo. The vision behind the design was that the athlete should be able to hand the app to a coach or parent and they should be able to track the stats with very little instruction, if they already understand the stats that are being tracked. I have gotten great feedback on this app, and since it doesn't rely on a third party service, it is my top stand alone project. Find it on the App Store!"

export const mvs = "When Multiversus released, a fighting game made by Warner Bros, I saw an oppertunity to be the first website to show player stats. I made a React site with a C# and became the first to document the API of the game. When multiversustracker.com released, it was the first Mulitversus tracker before sites like tracker.com. It made it to the top of Google and reached 37 thousand unique visitors in its first 60 days. The website is no longer running as the game has been taken down until it's full release."

export const portfolio = "When I wanted to make a place to show my projects, I wanted something that was unique. I learned Three.JS and made a 3D room with some of my own photography, a clock, and a laptop where you could see my projects. The laptop OS was made in React and made to look like Windows."