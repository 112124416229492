import logo from './logo.svg';
import './App.css';
import { useState, useEffect, useRef } from 'react';
import React from 'react';
import ug from './img/ug.png';
import home from './img/home.png';
import hclogo from './img/hclogo.png';
import github from './img/github.png'
import Draggable from 'react-draggable';
import closeicon from './img/close.png';
import maximizeicon from './img//maximize.png';
import minimizeicon from './img//minimize.png';
import wlogo from './img/windowslogo.png'
import Info from './info';

function App() {
  const [windows, setWindows] = useState([]);

  /*
  //Controls desktop icons
  */
  function DesktopIcon(props) {
    const ref = useRef();
    const [active, setActive] = useState(false);
    function onClick(e) {
      e.preventDefault();
      if (active)
      {
        setWindows([...windows, <Window name={props.name} img={props.img} src={props.src} size={props.size} />])
      }
      else
        setActive(true);
    }
    useOnClickOutside(ref, () => {
      setActive(false);
    });
    return (
      <div ref={ref} className={`desktopicon ${active ? 'active' : ''}`} onClick={onClick}>
        <img src={props.img} alt="Logo" />
        <p>{props.name}</p>
      </div>
    );
  }


  /*
    //Each window
    */
  function Window(props) {
    const id = Math.random()
    const [size, setSize] = useState(props.size);
    const minSize = { x: 350, y: 100 };
    const handler = (mouseDownEvent) => {
      const startSize = size;
      const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };
      function onMouseMove(mouseMoveEvent) {
        setSize(currentSize => ({
          x: Math.max(startSize.x - startPosition.x + mouseMoveEvent.pageX, minSize.x),
          y: Math.max(startSize.y - startPosition.y + mouseMoveEvent.pageY, minSize.y)
        }));
      }
      function onMouseUp() {
        document.body.removeEventListener("mousemove", onMouseMove);
        // uncomment the following line if not using `{ once: true }`
        // document.body.removeEventListener("mouseup", onMouseUp);
      }

      document.body.addEventListener("mousemove", onMouseMove);
      document.body.addEventListener("mouseup", onMouseUp, { once: true });
    };

    function close() {
      setWindows(windows.filter(x => x.id != id));
    }
    return (
      <Draggable bounds={{ top: 0 }} handle=".windowtop">
        <div style={{ width: size.x, height: size.y}} className={`windowframe`}>
          <div className={`windowtop`}>
            <img src={props.img} className="windowicon" alt="Logo" />
            <p>{props.name}</p>
            <div style={{ "marginLeft": "auto" }} className='barbutton'><img src={minimizeicon} /></div>
            <div className='barbutton'><img src={maximizeicon} /></div>
            <div className='barbutton' onClick={() => close()}><img src={closeicon} /></div>
          </div>
          {typeof props.src === "string" ? 
          (<iframe src={props.src}></iframe>) : 
          (props.src)
          }
          <div className='resize' onMouseDown={handler} />
        </div>
      </Draggable>
    );
  }

  const [time, setTime] = useState(new Date());
  //const timer = setInterval(() => setTime(new Date()), 1000)
  const timeToString = (date) =>
  {
    const d = time.toLocaleTimeString();
    return d.substring(0, d.length - 6) + d.substring(d.length - 3, d.length);
  }
  if(window.location.pathname.startsWith("/info"))
  {
    document.body.style.overflow = "auto";
    return(
    <div style={{"padding": "15px" }}><Info /></div>)
  }
  return (
    <div className="App">
      <DesktopIcon name="About Me" img={home} src={<Info />} size={{ x: 800, y: 600 }} />
      <DesktopIcon name="Ultimate Games" img={ug} src="https://ultimate-games.github.io/" size={{ x: 800, y: 600 }} />
      <DesktopIcon name="Havok Car" img={hclogo} src="https://ultimate-games.github.io/games/havokcar/havokframe/" size={{ x: 836, y: 500 }} />
      {windows.map((component, index) => (
        <React.Fragment key={index}>
          {component}
        </React.Fragment>
      ))}
      <div className='bottom-bar'>
        <img className='bottom-logo' src={wlogo}/>
        {windows.map((component, index) => (
        <React.Fragment key={index}>
          <img className='bottom-icon' src={component.props.img} />
        </React.Fragment>
      ))}
      <div className='bottom-date'>
          {timeToString(time)}{' '}{time.toLocaleDateString()}
      </div>
      </div>
    </div>
  );
}

export default App;




function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    [ref, handler]
  );
}